import React from "react";

// Customizable Area Start
import { styled, Typography, Button } from "@material-ui/core";
import { action, back, coverPicture, profilePlaceholder } from "./assets";
import Header from "../../../blocks/landingpage/src/Header.web";
import CustomFontVariant from "../../../components/src/CustomFontVariant.web";
import customTheme from "../../../components/src/CustomTheme.web";

interface Friend {
  attributes: {
    id: number;
    photo: string;
    name: string;
    mutual_friend: number;
  };
}
// Customizable Area End

import FriendsProfileController, {
  Props,
  configJSON,
} from "./FriendsProfileController";

export default class FriendsProfile extends FriendsProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Header navigation={this.props.navigation} id={""} />
        <Main1>
          <Back1 onClick={() => this.handleNavigation()} src={back}></Back1>
          <MainContainer1>
            <CoverProfile1
              profile={this.state.friendDetails.profile_cover || coverPicture}
            >
              <Profile1
                src={
                  this.state.friendDetails.profile_image || profilePlaceholder
                }
              ></Profile1>
            </CoverProfile1>
            <MainSelection1>
              <PrifileMain1 style={{ marginTop: 102, marginBottom: 30 }}>
                <ProfileName1>{this.state.friendDetails.name}</ProfileName1>
                <div style={{ display: "flex", gap: 30 }}>
                  <ButtonOutline1 variant="outlined">Unfriend</ButtonOutline1>
                  <ButtonContained1 variant="outlined" onClick={() => this.handleNavigation1("Chat")}>
                    Message
                  </ButtonContained1>
                  <img
                    style={{ cursor: "pointer" }}
                    src={action}
                    alt="editIcon"
                  />
                </div>
              </PrifileMain1>
              <ProfileDescription1>
                {this.state.friendDetails.email}
              </ProfileDescription1>
              <ProfileDescription1>
                {this.state.friendDetails.bio}
              </ProfileDescription1>
              <Divider1 />
              <PrifileMain1>
                <ProfileName1>Mutual Friends</ProfileName1>
                <ButtonOutline1
                  variant="outlined"
                  data-test-id="handleNavigation"
                  onClick={() => this.handleNavigation1("FriendsList")}
                >
                  See all friends
                </ButtonOutline1>
              </PrifileMain1>
              {this.state.friendDetails.mutual_friends?.map(
                (object: Friend, index) => (
                  <div style={{ marginTop: 16 }}>
                    <PrifileMain1 style={{ padding: "12px 0px" }} key={index}>
                      <FriendProfileMain1
                        onClick={() =>
                          this.handleGetFriendProfile(object.attributes.id)
                        }
                      >
                        <FriendProfileImg1 src={object.attributes.photo} />
                        <FriendInner1>
                          <FriendProfileName1>
                            {object.attributes.name}
                          </FriendProfileName1>
                          <FriendProfileMutual>
                            {object.attributes.mutual_friend}
                          </FriendProfileMutual>
                        </FriendInner1>
                      </FriendProfileMain1>
                      <ButtonContained1 variant="outlined">
                        {configJSON.Message}
                      </ButtonContained1>
                    </PrifileMain1>
                  </div>
                )
              )}
            </MainSelection1>
          </MainContainer1>
        </Main1>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const Main1 = styled("div")({
  padding: "40px 36px",
});

const MainContainer1 = styled("div")({
  borderRadius: 24,
  boxShadow: `
      0px 8px 32px 0px #0000000F,
      0px 4px 8px 0px #00000008,
      0px 25px 50px 0px #00000017
    `,
});

const Back1 = styled("img")({
  marginBottom: 20,
  cursor: "pointer",
});

const CoverProfile1 = styled("div")(
  ({ profile }: { profile: { profile: string } }) => ({
    backgroundImage: `url(${profile})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    minHeight: "217",
    borderRadius: "24px 24px 0px 0px",
    position: "relative",
  })
);

const Profile1 = styled("img")({
  width: 152,
  height: 152,
  borderRadius: "50%",
  border: `4px solid ${customTheme.palette.white.main}`,
  position: "absolute",
  bottom: "-78px",
  left: 33,
  backgroundSize: "cover",
  backgroundPosition: "center",
});

const MainSelection1 = styled("div")({
  paddingInline: 36,
  paddingBottom: 40,
});

const PrifileMain1 = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const ProfileName1 = styled(Typography)({
  ...CustomFontVariant.palette.font30700,
});

const ProfileDescription1 = styled("div")({
  ...CustomFontVariant.palette.font16400,
  marginBottom: 16,
});

const Divider1 = styled("div")({
  borderBottom: `1px solid${customTheme.palette.grey.coolGrey}`,
  margin: "60px 0px",
});

const ButtonOutline1 = styled(Button)({
  padding: "10px 40px",
  ...CustomFontVariant.palette.font16700,
  textTransform: "none",
  borderColor: customTheme.palette.black.primary,
});

const ButtonContained1 = styled(Button)({
  padding: "10px 40px",
  backgroundColor: customTheme.palette.grey.primary,
  color: customTheme.palette.black.primary,
  ...CustomFontVariant.palette.font16700,
  textTransform: "none",
  border: "none",
});

const FriendProfileMain1 = styled("div")({
  display: "flex",
  cursor: "pointer",
  alignItems: "center",
});

const FriendProfileName1 = styled("div")({
  ...CustomFontVariant.palette.font20400,
});

const FriendProfileMutual = styled("div")({
  ...CustomFontVariant.palette.font14700,
  color: customTheme.palette.grey.main,
});

const FriendProfileImg1 = styled("img")({
  width: 56,
  height: 56,
  borderRadius: "50%",
});

const FriendInner1 = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: 8,
  paddingLeft: 10,
});

// Customizable Area End
