import React from "react";

// Customizable Area Start
import { Grid, styled, Typography } from "@material-ui/core";
import customFontVariant from "../../../components/src/CustomFontVariant.web";
import Header from "../../../blocks/landingpage/src/Header.web";
import customTheme from "../../../components/src/CustomTheme.web";
import AccountSettings from "./AccountSettings.web";
import PrivacySettings from "../../privacysettings/src/PrivacySettings.web";

const navigation:any = {
  navigate: () => {},
  goBack: () => {},
  reset: () => {},
  setParams: () => {},
  dispatch: () => {},
  setOptions: () => {},
  canGoBack: () => true,
  isFocused: () => true,
  addListener: () => () => {},
  removeListener: () => {},
  getState: () => {
    return {
      key: "",
      index: 0,
      routeNames: [""],
      history: [""],
      routes: [],
      type: "",
      stale: false,
    };
  },
  getParent: () => undefined 
};
// Customizable Area End

import AccountSettingsWebController, {
  Props,
} from "./AccountSettingsWebController";

export default class Settings extends AccountSettingsWebController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Header navigation={this.props.navigation} id={""} />
        <Main>
          <MainGrid container>
            <GridFirst item sm={4} md={4}>
              <Title>Settings</Title>
              {this.menu.map((item: any) => (
                <MenuItem key={item.id}>
                  <Menu
                    onClick={() => this.handleChange(item.id)}
                    value={this.state.value === item.id}
                    data-test-id="handleChange"
                  >
                    {item.name}
                  </Menu>
                </MenuItem>
              ))}
            </GridFirst>
            <GridSecond item sm={8} md={8}>
              <div>
                {this.state.value === 1 && (
                  <AccountSettings navigation={undefined} id={""} />
                )}
                {this.state.value === 2 && (
                  <PrivacySettings navigation={navigation} id={""} />
                )}
                {this.state.value === 3 && (
                  <Typography>Notification Settings</Typography>
                )}
              </div>
            </GridSecond>
          </MainGrid>
        </Main>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const MainGrid = styled(Grid)({
  borderRight: `1px solid${customTheme.palette.grey.coolGrey}`,
  borderLeft: `1px solid${customTheme.palette.grey.coolGrey}`,
});

const Main = styled("div")({
  padding: "0px 40px",
});
const Title = styled(Typography)({
  padding: "16px 0px 30px 24px",
  ...customFontVariant.palette.font30700,
  color: customTheme.palette.black.primary,
  borderBottom: `1px solid${customTheme.palette.grey.coolGrey}`,
});

const MenuItem = styled("div")({
  marginBottom: 8,
  marginTop: 24,
  padding: "0px 8px",
});

const Menu = styled(Typography)(({ value }: any) => ({
  borderRadius: 10,
  padding: "16px",
  backgroundColor: value ? customTheme.palette.grey.primary : "transparent",
  ...customFontVariant.palette.font16700,
  color: customTheme.palette.black.primary,
  cursor: "pointer",
}));

const GridFirst = styled(Grid)({
  borderRight: `1px solid${customTheme.palette.grey.coolGrey}`,
  maxWidth: 362,
});

const GridSecond = styled(Grid)({
  padding: "24px",
});

// Customizable Area End
