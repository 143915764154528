import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData, setStorageData } from "framework/src/Utilities";
interface ApiCallData {
  contentType: string;
  method: string;
  endPoint: string;
  body?: {};
  type?: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  tabValue: number;
  currentPage: number;
  friendsPerPage: number;
  search: string;
  FriendsList: [];
  totalCount: number;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class FriendsListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getFriendListCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      tabValue: 0,
      currentPage: 1,
      friendsPerPage: 8,
      search: "",
      FriendsList: [],
      totalCount: 1,
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getFriendListCallId) {
        if (!responseJson.message) {
          this.setState({
            FriendsList: responseJson.data,
            currentPage: responseJson.current_page,
            totalCount: responseJson.total_pages,
          });
        } else {
          this.setState({ FriendsList: [] });
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    const { tabValue, search, currentPage, friendsPerPage } = this.state;
    this.handleGetFriendProfile(
      tabValue === 1,
      search,
      currentPage,
      friendsPerPage
    );
  }

  apiCall = async (data: ApiCallData) => {
    const token = await getStorageData("token");
    const { contentType, method, endPoint, body, type } = data;
    const header = { "Content-Type": contentType, token };
    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    request.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body && type != "formData"
      ? request.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        )
      : request.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    runEngine.sendMessage(request.id, request);
    return request.messageId;
  };

  handleNavigation = async (path: string, connectionId?: number) => {
    const toNavigate: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), path);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
    if (connectionId) {
      setStorageData("connectionId", connectionId); 
    }
  };

  handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    const { search, currentPage, friendsPerPage } = this.state;
    this.setState({ tabValue: newValue });
    this.handleGetFriendProfile(
      newValue === 1,
      search,
      currentPage,
      friendsPerPage
    );
  };

  handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const { tabValue, currentPage, friendsPerPage } = this.state;
    this.setState({ search: value });
    this.debouncedSearch(tabValue === 1, value, currentPage, friendsPerPage);
  };

  handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    const { tabValue, search, friendsPerPage } = this.state;
    this.setState({ currentPage: page });
    this.handleGetFriendProfile(tabValue === 1, search, page, friendsPerPage);
  };

  handleGetFriendProfile = async (
    tabValue?: boolean,
    search?: string,
    currentPage?: number,
    friendsPerPage?: number
  ) => {
    this.getFriendListCallId = await this.apiCall({
      method: "GET",
      endPoint: `bx_block_addfriends/my_connections?recently_added=${tabValue}&search=${search}&page=${currentPage}&per=${friendsPerPage}`,
      contentType: "application/json",
    });
  };

  debouncedSearch = this.debounce(this.handleGetFriendProfile, 300);

  debounce(func: (...args: any) => any, delay: number) {
    let timeout: NodeJS.Timeout | undefined;
    return function(...args: any) {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }

  // Customizable Area End
}
