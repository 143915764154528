// Customizable Area Start
import React from "react";
import { styled } from "@mui/material";
import { Button, Typography, Avatar, AppBar, Tabs, Tab, Box, MenuItem, Menu, Dialog, DialogTitle, DialogContent, DialogActions, Grid, IconButton } from "@material-ui/core";
import { Vector, HomeImage, CloseIcon, LeftArrow, RightArrow, ThumbUp, ChatBubble, Reply, Flag } from './assets';
import { Theme } from "@mui/material/styles";
import "@mui/styles";
import Header from "../../landingpage/src/Header.web";
import CustomFontVariant from "../../../components/src/CustomFontVariant.web"
import ContentModerationController, {
  configJSON,
  Event
} from "./ContentModerationController";
import customTheme from "../../../components/src/CustomTheme.web";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const Main2 = styled("div")({
  padding: "40px 36px",
});

const MainContainer2 = styled("div")({
  borderRadius: 24,
  boxShadow: `
      0px 8px 32px 0px #0000000F,
      0px 4px 8px 0px #00000008,
      0px 25px 50px 0px #00000017
    `,
});

const MainSelection2 = styled("div")({
  paddingInline: 36,
  padding: "40px 35px",
});

const HomesLeftPanel = styled("div")({
  display: "flex",
})

const Heading2 = styled("div")({
  ...CustomFontVariant.palette.font30700,
  marginBottom: "40px"
});

const MyHomes = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingTop: "15px"
});

const AddressText = styled(Typography)({
  ...CustomFontVariant.palette.font20400,
  paddingTop: "8px"
});

const ManageButton = styled(Button)({
  padding: "10px 40px",
  ...CustomFontVariant.palette.font16700,
  textTransform: "none",
  backgroundColor: customTheme.palette.grey.primary,
  '&: hover': {
    backgroundColor: customTheme.palette.grey.primary,
  }
});

const Divider = styled("div")({
  borderBottom: `3px solid #F1F5F9`,
  margin: "10px 0px",
});

const Divider2 = styled("div")({
  borderBottom: `1px solid #CBD5E1`,
  margin: "60px 0px",
});

const TabContentDivider = styled("div")({
  borderBottom: `1px solid #E2E8F0`,
  margin: "0px 0px 25px"
});

const VerticleDivider = styled("div")({
  borderLeft: `1px solid #64748B`,
  height: "17px",
  margin: "5px 10px",
});

const EventTitle = styled(Typography)({
  ...CustomFontVariant.palette.font18700,
  marginBottom: "20px"
});

const EventDescription = styled(Typography)({
  ...CustomFontVariant.palette.font16400,
  marginBottom: "20px"
});

const EventButton = styled(Button)({
  padding: "10px 40px",
  ...CustomFontVariant.palette.font16700,
  textTransform: "none",
  backgroundColor: customTheme.palette.grey.primary,
  '&: hover': {
    backgroundColor: customTheme.palette.grey.primary
  }
});

const CancelButton = styled(Button)({
  padding: "10px 40px",
  ...CustomFontVariant.palette.font16700,
  textTransform: "none",
  backgroundColor: "none",
  border: "1px solid #000000",
  borderRadius: "5px",
  '&: hover': {
    background: "none"
  }
});

const ConfirmButton = styled(Button)({
  padding: "10px 40px",
  ...CustomFontVariant.palette.font16700,
  textTransform: "none",
  backgroundColor: customTheme.palette.grey.primary,
  borderRadius: "5px",
  '&:hover': {
    background: customTheme.palette.grey.primary
  }
});

const CommentText = styled(Typography)({
  ...CustomFontVariant.palette.font18400,
  paddingBottom: "10px"
});

const ShowMoreBtn = styled(Box)({
  ...CustomFontVariant.palette.font16700,
  float: "right",
  paddingRight: "21px",
  color: customTheme.palette.grey.extraLight,
  textTransform: "none",
  ...CustomFontVariant.palette.font18700,
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "white"
  },
});

const MorevertBtn = styled(Button)({
  minWidth: "10px",
  padding: 0
})

const StyledMenu = styled((props: any) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 25,
    minWidth: 225,
    top: "437px !important",
    left: "1735px !important",
    color: 'rgb(55, 65, 81)',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiList-root': {
      padding: "20px"
    },
    '& .MuiMenuItem-root': {
      padding: "15px",
      width: "250px",
      "&:hover": {
        backgroundColor: "#EAE7DC"
      },
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      }
    },
  },
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paperWidthSm': {
    width: "600px",
    height: "225px",
    padding: "40px",
    borderRadius: "25px"
  },
  '& .MuiDialogContent-root': {
    padding: "30px 0px 30px",
    flex: "0 1 auto",
    '& .MuiTypography-body1': {
      ...CustomFontVariant.palette.font20400,
      color: "#334155"
    }
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialogTitle-root': {
    padding: "0px",
    '& .MuiTypography-h6': {
      ...CustomFontVariant.palette.font20700,
    }
  }
}));

const AcceptButton = styled(Button)({
  ...CustomFontVariant.palette.font16700,
  background: "#D1FAE5",
  color: "#059669",
  width: "144px",
  height: "44px",
  borderRadius: "8px",
  textTransform: "none",
  '&: hover': {
    background: "#D1FAE5",
  }
});

const RejectButton = styled(Button)({
  ...CustomFontVariant.palette.font16700,
  background: "#FEE2E2",
  color: "#DC2626",
  width: "144px",
  height: "44px",
  borderRadius: "8px",
  textTransform: "none",
  '&: hover': {
    background: "#FEE2E2",
  }
});

const ImageText = styled(Typography)({
  ...CustomFontVariant.palette.font24700,
  color: "#FFFFFF",
  position: "absolute",
  left: "45%",
  top: "50%",
  width: "20px"
})

const CarouselDialog = styled(Dialog)({
  '& .MuiDialog-paperWidthSm': {
    maxWidth: "1184px"
  },
  '& .MuiDialogTitle-root': {
    padding: 0
  }
});

const address = [
  {
    text: "4517 Washington Ave, Manchester, Kentucky 39495"
  },
  {
    text: "6391 Elgin St. Celina, Delaware 10299"
  },
  {
    text: "2972 Westhelmer Rd. Santa Ana, Illinois 85486"
  },
];

const comments = [
  {
    userName: "A**** ******n",
    decsription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    date: "19 April 2024",
    time: "16:23 AM",
    profileUrl: "https://placekitten.com/250/250"
  },
  {
    userName: "M**** ****p",
    decsription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    date: "19 April 2024",
    time: "16:23 AM",
    profileUrl: "https://avatar.iran.liara.run/public/boy?username=Ash"
  },
  {
    userName: "A*** ****t",
    decsription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    date: "19 April 2024",
    time: "16:23 AM",
    profileUrl: "https://loremflickr.com/250/250/dog"
  }
]

class ContentModeration extends ContentModerationController {

  TabPanel = (props: {children: any, value: number, index: number}) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  renderComments(comments: {
    userName: string,
    decsription: string,
    date: string,
    time: string,
    profileUrl: string
  }[]) {
    return (<>
     <Typography style={{...CustomFontVariant.palette.font18700, color: "#000000", margin: "30px 0 20px"}}>{(this.state.activeFlow !== "event" && this.state.tabValue === 0) ? "Posts" : "Reported Content"}</Typography>
      {comments.map((data) => {
        return <><MyHomes>
          <HomesLeftPanel>
            <Avatar
              style={{ cursor: "pointer", marginRight: "20px", width: "55px", height: "55px" }}
              src={data.profileUrl}
            />
            <div>
              <CommentText>{data.userName}</CommentText>
              <CommentText>{data.decsription}</CommentText>
              <div style={{ display: "flex" }}>
                <CommentText style={{ color: "#64748B" }} >{data.time}</CommentText>
                <VerticleDivider />
                <CommentText style={{ color: "#64748B" }} >{data.date}</CommentText>
              </div>
              {
                (this.state.activeFlow !== "event" && this.state.tabValue === 0 && <Grid container spacing={2} style={{width: "220px", display: "flex", alignItems: "center", margin: "0px 0 5px"}}>
                  <Grid style={{ display: "flex", alignItems: "self-start", gap: "5px", padding: "0" }} item xs={3}>
                    <Typography>5</Typography>
                    <img
                      style={{ cursor: "pointer"}}
                      src={ThumbUp}
                      alt="ThumbUp"
                    />
                  </Grid>
                  <Grid item xs={3} style={{ display: "flex", alignItems: "self-start", gap: "5px"}}>
                  <Typography>5</Typography>
                    <img
                      style={{ cursor: "pointer"}}
                      src={ChatBubble}
                      alt="ChatBubble"
                    />
                  </Grid>
                  <Grid item xs={3} style={{flexBasis: "18%"}}>
                    <img
                      src={Reply}
                      alt="Reply"
                      style={{ cursor: "pointer"}}
                    />
                  </Grid>
                  <Grid item xs={3} style={{flexBasis: "18%"}}>
                    <img
                      src={Flag}
                      alt="Flag"
                      style={{ cursor: "pointer"}}
                    />
                  </Grid>
                </Grid>)
              }
            </div>
          </HomesLeftPanel>
          <MorevertBtn data-test-id="MorevertBtn" onClick={(event: React.MouseEvent<HTMLButtonElement>) => { this.setState({ anchorEl: event.currentTarget }) }}
          >
            <img
              style={{ cursor: "pointer" }}
              src={Vector}
              alt="morevert"
            />
          </MorevertBtn>
        </MyHomes>
          <TabContentDivider style={{ marginTop: "0px", marginBottom: "25px" }} /></>
      })}
      <StyledMenu
        id="demo-customized-menu"
        data-test-id="menu-list"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={this.state.anchorEl}
        open={this.state.anchorEl}
        onClose={() => this.setState({ anchorEl: null })}
      >
        <MenuItem data-test-id="menuList-item" onClick={() => this.setState({ isDialogOpen: true, dialogHeader: "Remove" })} disableRipple>
          Remove
        </MenuItem>
        <MenuItem data-test-id="menuList-item" onClick={() => this.setState({ isDialogOpen: true, dialogHeader: "Restrict user from posting" })} disableRipple>
          Restrict user from posting
        </MenuItem>
      </StyledMenu>
      {this.renderDialog()}
    </>
    )
  }

  renderPhotos() {
    return (
      <>
      <div>
      <Typography style={{ ...CustomFontVariant.palette.font18700, padding: "25px 0 30px" }}>Photos</Typography>
        <Avatar
          style={{ cursor: "pointer", marginRight: "20px" }}
          src={'https://avatar.iran.liara.run/public/boy?username=Ash'}
        />
        <Typography style={{ ...CustomFontVariant.palette.font16700, paddingTop: "15px", paddingBottom: "20px" }}>M*** ****p</Typography>
      </div>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} style={{ gap: "24px", margin: "0px" }}>
            <Grid style={{ padding: 0, maxWidth: "50.333%" }} item xs={4}>
              <img
                style={{ cursor: "pointer", width: "775px" }}
                src={HomeImage}
                alt="HomeImage"
              />
            </Grid>
            <Grid item xs={4} style={{ padding: 0, maxWidth: "50.333%", position: "relative", textAlign: "center" }}>
              <div
                data-test-id="photo-grid"
                style={{
                  cursor: "pointer",
                  width: "775px",
                  height: "auto",
                  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), 
                          url(${HomeImage})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                onClick={() => this.setState({ isCarouselOpen: true })}
              >
                <img
                  src={HomeImage}
                  alt="HomeImage"
                  style={{ visibility: "hidden", width: "775px" }}
                />
                <ImageText>+3 More</ImageText>
              </div>
            </Grid>
            <Grid item xs={4} style={{ padding: 0, maxWidth: "8.333%", display: "grid", alignContent: "center", gap: "16px", margin: "auto" }}>
              <AcceptButton>Approve all</AcceptButton>
              <RejectButton>Reject</RejectButton>
            </Grid>
          </Grid>
        </Box>
        <div style={{ display: "flex", margin: "20px 0 5px" }}>
                <CommentText style={{ color: "#64748B" }} >16:23 AM</CommentText>
                <VerticleDivider />
                <CommentText style={{ color: "#64748B" }} >19 April 2024</CommentText>
        </div>
        <TabContentDivider />
        <CarouselDialog
          open={this.state.isCarouselOpen}
          PaperProps={{
            style: {
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
          }}
        >
          <DialogContent style={{ position: 'relative' }}>
            <IconButton
              aria-label="close"
              data-test-id="close-btn"
              onClick={() => this.setState({ isCarouselOpen: false })}
              style={{
                position: "absolute",
                right: 135,
                top: 10,
                width: "36px",
                height: "36px",
                borderRadius: "60px",
                padding: "6px",
                background: "#EAE7DC",
                zIndex: 2,
              }}
            >
              <img src={CloseIcon} alt="close icon" />
            </IconButton>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2} style={{ gap: "85px" }}>
                <Grid style={{ maxWidth: "5.333%", display: "grid", alignContent: "center", gap: "16px", margin: "auto" }} item xs={4}>
                  <IconButton
                    aria-label="close"
                    style={{
                      width: "36px",
                      height: "36px",
                      borderRadius: "60px",
                      padding: "6px",
                      background: "#EAE7DC"
                    }}
                  >
                    <img src={LeftArrow} alt="leftArrow icon" />
                  </IconButton>
                </Grid>
                <Grid item xs={4} style={{ padding: 0, maxWidth: "82.333%", position: "relative", textAlign: "center" }}>
                  <img style={{ width: '700px', height: "544px" }} src={HomeImage} alt="carousel image" />
                  <div style={{ gap: '89px', display: "flex", justifyContent: "center", padding: "15px 0", borderRadius: "8px", background: "#FFFFFF" }}>
                    <RejectButton>Reject</RejectButton>
                    <AcceptButton>Approve</AcceptButton>
                  </div>
                </Grid>
                <Grid item xs={4} style={{ maxWidth: "5.333%", display: "grid", alignContent: "center", gap: "16px", margin: "auto" }}>
                  <IconButton
                    aria-label="close"
                    style={{
                      width: "36px",
                      height: "36px",
                      borderRadius: "60px",
                      padding: "6px",
                      background: "#EAE7DC"
                    }}
                  >
                    <img src={RightArrow} alt="rightArrow icon" />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
        </CarouselDialog>
      </>
    )
  }

  renderEvent() {
    const { activeFlow } = this.state;

    return (
      <div style={{marginBottom: "60px"}}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {activeFlow === "address" && <Avatar
            style={{ cursor: "pointer", marginRight: "20px" }}
            src={'https://avatar.iran.liara.run/public/boy?username=Ash'}
          />}
          <Typography style={{ ...CustomFontVariant.palette.font20400 }}>{activeFlow === "event" ? this.state.selectedEvent.attributes.event_name : this.state.addressData.text}</Typography>
        </div>
        <Divider />
        <AppBar position="static" style={{color: "black", background: "white", boxShadow: "none", marginTop: "70px"}}>
          <Tabs
            data-test-id="event-tabs"
            centered
            value={this.state.tabValue}
            onChange={this.handleChange}
            variant="fullWidth"
            aria-label="full width tabs example"
            TabIndicatorProps={{
              style: {
                backgroundColor: 'black', height: '4px',
              },
            }}>
            <Tab style={{ textTransform: "none", fontSize: "16px" }} label={activeFlow === "event" ? "Comments" : "Guest Book"} {...this.a11yProps(0)} />
            <Tab style={{ textTransform: "none", fontSize: "16px" }} label={activeFlow === "event" ? "Reported content" : "Photos"} {...this.a11yProps(1)} />
            {activeFlow !== "event" && <Tab style={{ textTransform: "none", fontSize: "16px" }} label="Reported content" {...this.a11yProps(2)} />}
          </Tabs>
        </AppBar>
        <this.TabPanel value={this.state.tabValue} index={0}>
          {this.renderComments(comments)}
        </this.TabPanel>
        <this.TabPanel value={this.state.tabValue} index={1}>
          {
            activeFlow === "event" ? this.renderComments(comments) : this.renderPhotos()
          }
        </this.TabPanel>
        {
          activeFlow === "address" && <this.TabPanel value={this.state.tabValue} index={2}>
            {this.renderComments(comments)}
          </this.TabPanel>
        }
        <ShowMoreBtn>show more</ShowMoreBtn>
      </div>
    );
  }

  renderDialog() {
    const { tabValue, dialogHeader } = this.state;

    return (
      <StyledDialog data-test-id="menuList-dialog" onClose={() => this.setState({ isDialogOpen: false })} open={this.state.isDialogOpen} aria-labelledby="customized-dialog-title" >
        <DialogTitle>{dialogHeader}</DialogTitle>
        <DialogContent>
          <Typography>
            {
              tabValue === 0 && <>
                {
                  dialogHeader === "Remove" && "Are you sure you want to remove Matt Taylor comment?"
                }
                {
                  dialogHeader !== "Remove" && "Are you sure you want to restrict Matt Taylor from posting?"
                }
              </>
            }
            {
              tabValue !== 0 && <>
                {
                  dialogHeader === "Remove" && "Are you sure that you want to remove this reported content from the group?"
                }
                {
                  dialogHeader !== "Remove" && "Are you sure you want to restrict Matt Taylor from posting?"
                }
              </>
            }
          </Typography>
        </DialogContent>
        <DialogActions>
          <CancelButton data-test-id="cancel-btn" onClick={() => this.setState({ isDialogOpen: false })}>
            Cancel
          </CancelButton>
          <ConfirmButton data-test-id="confirm-btn" onClick={() => this.setState({ isDialogOpen: false })}>
            Confirm
          </ConfirmButton>
        </DialogActions>
      </StyledDialog>
    )
  }

  render() {
    return (
      <>
        <Header activeTab="" navigation={this.props.navigation} id={""} />
        <Main2>
          <MainContainer2>
            <MainSelection2>
              {this.state.activeFlow === "" && <><Heading2>{configJSON.homeTitleText}</Heading2>
                {address.map(data => {
                  return (<>
                    <MyHomes>
                      <HomesLeftPanel>
                        <Avatar
                          style={{ cursor: "pointer", marginRight: "20px" }}
                          src={'https://avatar.iran.liara.run/public/boy?username=Ash'}
                        />
                        <AddressText>{data.text}</AddressText>
                      </HomesLeftPanel>
                      <ManageButton data-test-id="address-manage" onClick={() => this.setState({ activeFlow: "address", addressData: data })}>
                        Manage
                      </ManageButton>
                    </MyHomes>
                    <Divider />
                  </>)
                })}
                <Divider2 />
                <Heading2>{configJSON.eventsText}</Heading2>
                {
                  this.state.eventData?.map((data: Event) => <>
                    <EventTitle>{data.attributes.event_name}</EventTitle>
                    <EventDescription>
                      {data.attributes.description}
                    </EventDescription>
                    <EventButton data-test-id="event-manage" onClick={() => this.setState({ activeFlow: "event", selectedEvent: data })}>Manage</EventButton>
                    <Divider style={{ marginTop: "25px" }} />
                  </>)
                }</>}
              {this.state.activeFlow && this.renderEvent()}
            </MainSelection2>
          </MainContainer2>
        </Main2>
      </>
    );
  }
}

export default ContentModeration;

// Customizable Area End
