// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { setStorageData, getStorageData } from "framework/src/Utilities";
export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
}

export type Event = {
  id: string;
  type: string;
  attributes: {
      name: string;
      is_public: string;
      profile_image: string | null;
      event_name: string;
      event_date: string;
      event_time: string;
      description: string;
      location: string;
      latitude: number;
      longitude: number;
      images: Array<{
          id: number;
          file_name: string;
          file_url: string;
      }>;
      hashtags: Array<string>;
      like: number;
      current_user_like: boolean;
      flag: boolean;
      comments: number;
  };
};


interface S {
  activeFlow: string;
  tabValue: number;
  anchorEl: HTMLElement | null;
  isDialogOpen: boolean,
  isCarouselOpen: boolean,
  dialogHeader: string,
  selectedEvent: Event,
  addressData: {
    text: string
   },
   eventData: Event[]
}

interface SS {
  id: any;
}

export default class ContentModerationController extends BlockComponent<
  Props,
  S,
  SS
> {
  loginDataApiCallId: string | null = "";
  getEventsDataApiCallId: string | null = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      activeFlow: "",
      tabValue: 0,
      anchorEl: null,
      isDialogOpen: false,
      isCarouselOpen: false,
      dialogHeader: "",
      selectedEvent:  {
        id: '',
        type: 'event',
        attributes: {
          name: '',
          is_public: '',
          profile_image: null,
          event_name: '',
          event_date: '',
          event_time: '',
          description: '',
          location: '',
          latitude: 0,
          longitude: 0,
          images: [],
          hashtags: [],
          like: 0,
          current_user_like: false,
          flag: false,
          comments: 0,
        },
      },
      addressData: {
        text: ""
       },
       eventData: []
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getEventsDataApiCallId) {
        this.handleEventsData(responseJson)
      }

      if (
        apiRequestCallId === this.loginDataApiCallId &&
        !responseJson.errors
      ) {
        await setStorageData("TOKEN", responseJson.meta.token);
      }
    }
  }

  contentModerationApiCall = async (data: any) => {
    const token = await getStorageData("token");
    const { contentType, method, endPoint, body, type } = data;
    const header = { "Content-Type": contentType, token };
    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    request.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body && type != "formData"
      ? request.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        )
      : request.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    runEngine.sendMessage(request.id, request);
    return request.messageId;
  };

  handleEventsData = (responseJson: {"data": Event[], "errors": [{token: ""}] }) => {
    if (responseJson.data) {
      this.setState({ eventData: responseJson.data }); 
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      console.log('Errors ', responseJson.errors)
    }
  };

  getEventsData = async () => {
    this.getEventsDataApiCallId = await this.contentModerationApiCall({
      method: "GET",
      endPoint: "bx_block_location/events/moderator_index",
      contentType: "application/json",
    });
  };

  async componentDidMount() {
    await this.getEventsData();
  }

  navigateToTagList = () => {
    this.props.navigation.navigate("CreateContent");
  };

  navigateToAnalyse = () => {
    this.props.navigation.navigate("ContentList");
  };

  handleChange = (event: any, newValue: number) => {
    this.setState({tabValue: newValue});
    console.log(event)
  };
}

// Customizable Area End
